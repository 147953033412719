<template>
  <div class="login-container">
    <div class="login-form">
      <img src="@/assets/vsu_logo_official.png" class="user-avatar center-logo">
      <h3 class="title">VSU HRIS</h3>

      <p v-if="!response" class="white-text" style="text-align: center">
        <img src="@/assets/loading.gif" class="user-avatar" style="width: 35px;display: inline;margin-bottom: -10px;margin-right: 10px;">
        <span>Verifying. Please wait ...</span>
      </p>

      <div v-if="response && response === 'error'" class="white-text text-center">
        <div style="padding-bottom: 15px;">
          <el-divider>Error</el-divider>
        </div>
        <div style="padding-bottom: 30px;">
          <h3>{{ responseMsg }}</h3>
          <p>{{ responseMsgContent }}</p>
        </div>

        <el-button type="primary" icon="el-icon-back" @click="$router.push('/login')">Go back to login page</el-button>

      </div>
    </div>
  </div>
</template>

<script>

import { BackToLogin } from '../layout/components'

export default {
  name: 'VerifySocialOAuth',
  components: { BackToLogin },
  data() {
    return {
      response: null,
      responseMsg: null,
      responseMsgContent: null
    }
  },
  created: function() {
    if (this.$route.query.response && this.$route.query.response === 'error') {
      this.response = this.$route.query.response
      this.responseMsg = this.$route.query.msg
      this.responseMsgContent = this.$route.query.msg_content
    } else if (this.$route.query && this.$route.query.token && this.$route.query.email) {
      this.storeSession({ token: this.$route.query.token, email: this.$route.query.email }).then(() => {
        this.$router.push('/dashboard')
      })
    } else {
      this.$router.push('/login')
    }
  },
  methods: {
    storeSession(params) {
      return this.$store.dispatch('LoginOAuth', params)
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg:#2d3a4b;
  $light_gray:#eee;

  .text-center { text-align: center; }

  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: #fff !important;
        }
      }
    }
    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    }
  }

</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  $bg:#004009;
  $dark_gray:#889aa4;
  $light_gray:#eee;
  .login-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $bg;
    .login-form {
      position: absolute;
      left: 0;
      right: 0;
      width: 520px;
      max-width: 100%;
      padding: 35px 35px 15px 35px;
      margin: 120px auto;
    }
    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }
    .title {
      font-size: 26px;
      font-weight: 400;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
</style>
